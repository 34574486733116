import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ModulesProviders from './modules/providers';

// import 'semantic-ui-css/semantic.min.css';
import THEME from './themes';
import { ThemeProvider } from 'styled-components';
import './style.scss';

/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/array/fill';
import 'core-js/features/object/values'
import 'core-js/features/number/is-nan';


ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={THEME}>
            <ModulesProviders>
                <App />
            </ModulesProviders>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
