import makeStore from "../../utils/create-store";
import reducer from "./reducer";

const User_MODULE_LS_KEY = 'User-module'

export type UserModuleState = {
    name?: string,
    emblem? : string,
    completedTasks: number[],
    awards: string[]
}

export const initUserModuleState: UserModuleState = {
    completedTasks: [],
    awards: []
}

export enum USER_MODULE_ACTION {
    SET_NAME = 'SET_NAME',
    COMPLETE_TASK = 'COMPLETE_TASK',
    RESET = 'RESET',
    SET_EMBLEM = "SET_EMBLEM"
}

export type UserModuleAction = {
    type: USER_MODULE_ACTION,
    payload?: any
}

const [
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
] = makeStore(reducer, initUserModuleState, User_MODULE_LS_KEY)


export {
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
}
