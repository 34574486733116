import React, { useMemo, useCallback, useState, useReducer } from "react";
import './style.scss';
import PlayerAndDrop from "./player-and-drop";

export default function WordsGame() {

    const DATA = useMemo(() => {
        return [
            {
                label: 'autobus',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/autobus.mp3',
            },
            {
                label: 'czekolada',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/czekolada.mp3',
            },
            {
                label: 'gitara',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/gitara.mp3',
            },
            {
                label: 'keczup',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/keczup.mp3',
            },
            {
                label: 'pizza',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/pizza.mp3',
            },
            {
                label: 'robot',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/robot.mp3',
            },
            {
                label: 'spaghetti',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/spagetti.mp3',
            },
            {
                label: 'sweter',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/sweter.mp3',
            },
            {
                label: 'żyrafa',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/%C5%BCyrafa.mp3',
            }
        ]
    }, [])

    const RANDOM_DATA = useMemo(() => {
        return [...DATA].sort(() => Math.random() - .5)
    }, [DATA]);

    const [activeWord, setActiveWord] = useState('')
    const [correctCount, addCorrect] = useReducer((state: number) => {
        return ++state;
    }, 0)

    const onDragStart = useCallback((e: React.DragEvent) => {
        e.dataTransfer.setData('text', e.currentTarget.id)
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.setData('text', e.currentTarget.id);

        setActiveWord(e.currentTarget.id)

    }, [])

    const onDragEnd = useCallback((e) => {
        setTimeout(() => {
            setActiveWord('')
        }, 1000)
    }, [setActiveWord])

    const onCorrect = useCallback((id: string) => {
        addCorrect()
    }, [])

    return (
        <>
            <div className='words-game'>
                <div className="player-list">
                    {
                        DATA.map((e, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <PlayerAndDrop
                                        audio={e.audio}
                                        answer={e.label}
                                        activeWord={activeWord}
                                        onCorrect={onCorrect}
                                    />
                                </React.Fragment>
                            )
                        })
                    }

                </div>
                <div className="pickzones">

                    {
                        RANDOM_DATA.map((e, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="pickzone"
                                        id={e.label}
                                        draggable={true}
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                    >
                                        <span
                                        >{e.label}</span>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
            {(correctCount === DATA.length) && <p>Gratulacje!</p>}
        </>
    )
}
