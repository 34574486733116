import React, { useReducer, useEffect, useCallback } from "react";
import './style.scss';

export default function Plants() {

    const DATA = [
        {
            img: '/tasks/403/1.png',
            label: 'Babka Lancetowata',
            isAnswer: true
        }, {
            img: '/tasks/403/2.png',
            label: 'Pokrzywa Zwyczajna',
            isAnswer: true
        }, {
            img: '/tasks/403/3.png',
            label: 'Pomidor Zwyczajny',
            isAnswer: false
        }, {
            img: '/tasks/403/4.jpg',
            label: 'Rumianek Pospolity',
            isAnswer: true
        }
    ]

    return (
        <div className="plants">
            <div className="plants-list">
                {DATA.map((d, index) => {
                    return (
                        <Plant
                            key={index}
                            imgSrc={d.img}
                            label={d.label}
                            isAnswer={d.isAnswer}
                        />
                    )
                })}
            </div>
        </div>
    )
}


type PProps = {
    imgSrc: string
    label: string
    isAnswer: boolean,
    onChange?: (state: boolean, label: string) => void
}

const Plant: React.FunctionComponent<PProps> = ({ imgSrc, label, isAnswer, onChange }) => {

    const [selected, toggleSelected] = useReducer((state: boolean) => {
        return !state;
    }, false)

    const onKeyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            toggleSelected();
        }
    }, [toggleSelected])

    useEffect(() => {
        if (onChange) onChange(selected, label)
    }, [selected, label, onChange])

    return (
        <figure
            role="checkbox"
            aria-checked={selected}
            tabIndex={0}
            onClick={toggleSelected}
            onKeyDown={onKeyDown}
            className={`${selected ? 'selected' : ''} ${isAnswer ? 'correct' : ''}`}
        >
            <img src={imgSrc} alt="" />
            <figcaption>
                {label}
            </figcaption>
        </figure>
    )
}
