import * as PIXI from 'pixi.js';

export class TrashBin extends PIXI.Container {
    public resources?: Partial<Record<string, PIXI.LoaderResource>>;

    private gfx = new PIXI.Graphics();
    private shop?: PIXI.Sprite;
    private _dropzone?: PIXI.Rectangle;

    public get dropzone() {
        if (this._dropzone)
            return this._dropzone;
        this._dropzone = new PIXI.Rectangle();
        this.getBounds(false, this.dropzone);
        
        return this._dropzone;
    }
    constructor(resources?: Partial<Record<string, PIXI.LoaderResource>>) {
        super();
        this.resources = resources;
        this.addChild(this.gfx);
        this.width = 200;
        this.height = 200;
        this.drawNormal();
        this.interactive = true;
    }
    
    public drawNormal() {
        this.gfx.clear();
        // this.gfx.beginFill(0x909090);
        this.gfx.drawRect(0, 0, 200, 200);
        this.gfx.endFill();

        if (this.resources) {
            this.shop = new PIXI.Sprite(this.resources.shop?.texture)            
            this.shop.height = 200;
            this.shop.width = 200;
            this.gfx.addChild(this.shop);
        }
    }
}
