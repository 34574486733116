import React, { useCallback, useState, useEffect } from "react";
import './style.scss';

export default function TipsGame() {

    const DATA = [
        {
            tips: [
                "Najpopularniejsze narzędzie elektryczne.",
                "Często działa w lewo i w prawo.",
                "Można do niego zamocować wiele końcówek.",
                "Najczęściej służy do robienia otworów."
            ],
            answers: [
                "wiertarka",
                "wiertarki",
                "wiertareczka",
                "wkretarka",
                "wiertarko wkretarka",
                "wiertarko-wkretarka",
                "wiertarko - wkretarka",
                "mlotowiertarka"
            ]
        },
        {
            tips: [
                "Można z tym narzędziem iść na spacer blisko domu.",
                "Bywają spalinowe, elektryczne i ręczne.",
                "Niezbędne, gdy chce się mieć piękny trawnik.",
                "Nie pozwala, by trawa urosła zbyt wysoko."
            ],
            answers: [
                "kosiarka",
                "kosiarki",
                "kosa",
                "podkaszarka"              
            ]
        },
        {
            tips: [
                "Najczęściej plastikowa, gliniana lub ceramiczna.",
                "Do postawienia lub powieszenia.",
                "Może stać sama lub w osłonce.",
                "Na domowe rośliny."
            ],
            answers: [
                "doniczka",
                "doniczki",
                "donica",
                "skrzynka balkonowa"             
            ]
        },
        {
            tips: [
                "Tym słowem określa się coś, co jest ciężkie i nieporęczne.",
                "Bywa pusta lub pełna.",
                "O charakterystycznym czerwonawym kolorze.",
                "Służy do budowy murów, pieców, ścian."
            ],
            answers: [
                "cegla",
                "cegly",
                "cegielka",
                "pustak",
                "dzirawka",
                "krarowka"               
            ]
        },
        {
            tips: [
                "Płaska i twarda.",
                "Może być wzorzysta, gładka lub jednokolorowa.",
                "Najczęściej spotkasz ją w łazience.",
                "Rozróżnia się ścienne i podłogowe."
            ],
            answers: [
                "plytka ceramiczna",
                "plytka",
                "plytki ceramiczne",
                "plytki",
                "ceramika",
                "ceramiczna",
                "glazura",
                "terakota",
                "kafelek",
                "kafel",
                "fliza",
                "kafelki",
                "lastryko",
                "gres"
            ]
        },
        {
            tips: [
                "Głęboka i długa.",
                "Coraz rzadziej spotykana w mieszkaniach, kiedyś wykonywana z żeliwa.",
                "Dzieci mogą w niej pływać.",
                "Miło się w niej położyć i zrelaksować."
            ],
            answers: [
                "wanna",
                "wanienka",
                "jacuzzi",
                "kadz" 
            ]
        },
        {
            tips: [
                "W sklepie zajmuje cały duży dział.",
                "Przyda się w szkole na plastyce i gdy chcesz zmienić wygląd.",
                "Ma tysiące ciekawych nazw.",
                "Najczęściej występuje w parze z pędzlem lub wałkiem."
            ],
            answers: [
                "farba",
                "farby",
                "kolor",
                "akwarela",
                "gwasz",
                "emulsja",
                "lakier",
                "tempera",
                "klejowka",
                "plakatowka",
                "farbka"
            ]
        }
    ]

    const correctChangeHandler = useCallback((state: boolean) => {
    }, [])

    return (
        <div className="tips-game">
            <div className='questions'>
                {DATA.map((d, index) => {
                    let tip = index + 1;
                    return (
                        <Question
                            key={index}
                            tip={tip}
                            tips={d.tips}
                            answers={d.answers}
                            correctChangeHandler={correctChangeHandler}
                        />
                    )
                })}
            </div>
            <div className='questions questions-image'>
                <div className="q-image">
                    <img src="/tasks/103/graf1.png" alt="iamge" />
                </div>
                <div className="q-image">
                    <img src="/tasks/103/graf2.png" alt="iamge" />
                </div>
                <div className="q-image">
                    <img src="/tasks/103/graf3.png" alt="iamge" />
                </div>
            </div>
        </div>
    )
}

type QProps = {
    tip: number,
    tips: string[],
    answers: string[],    
    correctChangeHandler: (state: boolean) => void
}

const Question: React.FunctionComponent<QProps> = ({ tip, tips, answers, correctChangeHandler }) => {

    const [isCorrect, setIsCorrect] = useState(false);
    const [countWrong, setCountWrong] = useState<number>(0);
    const [userAnswer, setUserAnswer] = useState<string>('');

    const checkAnswer = useCallback(() => {
        if (answers.includes(clearString(userAnswer))) {
            setIsCorrect(true);
        } else {
            setCountWrong(countWrong + 1);
            setIsCorrect(false);
        }
    }, [answers, userAnswer, countWrong, setCountWrong, setIsCorrect]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setUserAnswer(e.currentTarget.value);
    }, [setUserAnswer])

    useEffect(() => {
        correctChangeHandler(isCorrect)
    }, [isCorrect, correctChangeHandler])

    const clearString = (name:string) => {        
        name = name.toLowerCase(); 
        name = name.replace("ą","a");
        name = name.replace("ó","o");
        name = name.replace("ł","l");
        name = name.replace("ń","n");
        name = name.replace("ż","z");
        name = name.replace("ź","z");
        name = name.replace("ć","c");
        name = name.replace("ę","e");
        name = name.replace("ś","s");
        return name;
    }

    return (        
        <div className={`question ${isCorrect ? 'correct' : ''} ${(!isCorrect && countWrong) ? 'in-correct' : ''}`}>     
            <h3>Zagadka {tip}</h3>
            <ul>
                {
                    tips.map((t, index) => {
                        if (countWrong >= index) {
                            return (
                                <li key={index}>{t}</li>
                            )
                        }
                    })
                }
            </ul>
            <div>
                <input 
                    name={`q_${answers[0]}`}
                    type="text"
                    onChange={onChange}
                />
                
                <a className="button-image button-sprawdz"
                    onClick={checkAnswer}>
                </a>
            </div>    
        </div>      
    )

}
