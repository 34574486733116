export function getHomeRoute() {
    return '/';
}

export function getMapRoute() {
    return '/sklep'
}

export function getTaskRoute(id: string | number = ':id') {

    return taskIDToUri(String(id)) || `/zadanie/${id}`
}

export function getGeneratorRoute() {
    return '/dom';
}

export function getManualRoute() {
    return '/instrukcja';
}

export function getRulesRoute() {
    return '/zasady';
}

export function getEndRoute() {
    return '/koniec'
} 

export const TASK_TO_URI_TABLE: [string, string][] = [
    ['101', '/sklep-zadanie/portret'],
    ['102', '/sklep-zadanie/pamiec'],
    ['103', '/sklep-zadanie/podpowiedzi'],
    ['104', '/sklep-zadanie/ukladanka'],
    ['105', '/sklep-zadanie/gdzie-to-znajde'],
    ['106', '/sklep-zadanie/lazienka'],

    ['201', '/spolecznosc-zadanie/odglosy'],
    ['202', '/spolecznosc-zadanie/drzewoznawca'],
    ['203', '/spolecznosc-zadanie/bzyczace'],
    ['204', '/spolecznosc-zadanie/deszczowka'],
    ['205', '/spolecznosc-zadanie/sadzeniaki'],
    ['206', '/spolecznosc-zadanie/zwierzaki'],

    ['301', '/karnawal-zadanie/lampiony'],
    ['302', '/karnawal-zadanie/girlanda'],
    ['303', '/karnawal-zadanie/maski'],
    ['304', '/karnawal-zadanie/pisarze'],
    ['305', '/karnawal-zadanie/gry'],
    ['306', '/karnawal-zadanie/zabawa']
]

export function taskIDToUri(id: string): string | null {
    const reference = TASK_TO_URI_TABLE.find(data => data[0] === id);
    return reference? reference[1] : null;
}
