import React from "react";
import './style.scss'

type Props = {
    task: Task
}

const Task106: React.FunctionComponent<Props> = ({ task }) => {

    return (
        <div className="task-screen column task-106">

            <h1>{task.title}</h1>

            <p>
                Nie ma mieszkania bez łazienki! Może warto ją dobrze zaprojektować? <br/>
                Stwórzcie techniczny rzut z góry Waszej łazienki zgodnie z poniższą instrukcją.<br/>
                Poproście o pomoc dorosłego podczas wykonywania niezbędnych pomiarów i rysowania rzutu.<br/>
                Możecie też skorzystać z porad zawartych w naszym formularzu usługi projektowej. <a target="_blank" rel="noopener noreferrer" href="https://static02.leroymerlin.pl/files/media/pdf/878/2287878/LeroyMerlin_Formularz_spotkanie_z_projektantem-27072020.pdf">Do pobrania tu</a>.
            </p> 

            <section className="images-list">
                <div>
                    <img src="/tasks/106/lazienka1.png" alt="lazienka" />
                </div>
                <div>
                    <img src="/tasks/106/lazienka2.png" alt="lazienka" />
                </div>
                <div>
                    <img src="/tasks/106/lazienka3.png" alt="lazienka" />
                </div>
            </section>

            <section className='todo-list'>                            
                <p><span>1.</span>Zaczynamy od określenia wymiarów pomieszczenia.</p>
                <p><span>2.</span>Określamy, gdzie są drzwi, ewentualnie okno.</p>
                <p><span>3.</span>Zaznaczamy wannę lub kabinę prysznicową.</p>
                <p><span>4.</span>Uwzględniając rodzaj „toalety” (w zabudowie czy wolnostojąca), zaznaczamy  jej lokalizację.</p>
                <p><span>5.</span>Zaznaczamy lokalizację umywalki i lustra.</p>
                <p><span>6.</span>Zaznaczamy położenie pralki, jeśli znajduje się w łazience.</p>
                <p><span>7.</span>Zaznaczamy położenie szafek łazienkowych: może przy umywalce, może przy pralce.</p>
                <p><span>8.</span>Zaznaczamy położenie grzejnika i rysujemy jego kształt.</p>
                <p><span>9.</span>Zaznaczamy położenie i rodzaj oświetlenia.</p>
                <p><span>10.</span>Zaznaczamy wielkość płytek, ich rozmieszczenie i kolorystykę.</p>
                <p><span>11.</span>Rysujemy baterie umywalkowe i prysznicowe.</p>
            </section>    
    

        </div>)
}

export default Task106;
