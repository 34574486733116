import * as PIXI from 'pixi.js';

export class House extends PIXI.Container {    

    private houseSprite?: PIXI.Sprite;
    
    constructor(props: {
        house: PIXI.Texture;
        onChange: () => void;
    }) {
        super();
        this.houseSprite = new PIXI.Sprite(props.house)
        this.addChild(this.houseSprite);
    }

    public getYCorrection(): number {
        if (this.houseSprite) {
            const top = this.getLocalBounds().y;
            return -top;
        }

        return 0;
    }
}
