import React, { useState, useEffect } from 'react';
import MapV2 from '../../components/map2';
import { useLocation } from 'react-router';
import './style.scss'
import useUserCompletedTasks from '../../modules/user/hooks/use-user-completed-tasks';
import useSetUserCompleteTask from '../../modules/user/hooks/use-set-user-complete-task';

import Popup from './task-end-popup';

export default function MapPage() {
    const [popupInfo, setPopup] = useState<{ isShow: Boolean, task?: Task }>({
        isShow: false
    })

    const { state } = useLocation<{ task: Task }>()


    const comletedTasks = useUserCompletedTasks();
    const setCompletedTask = useSetUserCompleteTask();

    useEffect(() => {
        if (state) {

            if (comletedTasks.indexOf(state.task.id) === -1) {
                setPopup({
                    isShow: true,
                    task: state.task
                })
                setCompletedTask(state.task.id);
            }
        }
    }, [state, setPopup, setCompletedTask, comletedTasks])

    return (
        <div className="map-screen">
            <MapV2 />
            {popupInfo.isShow && popupInfo.task && <Popup
                task={popupInfo.task}
                onClick={() => { setPopup({ isShow: false }) }}
            />}
        </div>
    )
}

