import React from "react";
import TaskElements from "../../taks-elements";
import './style.scss'
import { getMapRoute, getGeneratorRoute } from "../../../../routes";
import { Link } from "react-router-dom";

type Props = {
    task: Task
}

const Task407: React.FunctionComponent<Props> = ({ task }) => {
    return (
        <div className="task-screen column task-407">

            <h1>{task.title}</h1>

            <TaskElements
                elements={task?.elements || []}
            />

            <div className="buttons">
                <Link className="button-big-blue" to={getMapRoute()}>Rozwiń mapę</Link>
                <Link className="button-big-blue" to={getGeneratorRoute()}>Przejdź do zamku</Link>
            </div>
        </div>)
}

export default Task407;
