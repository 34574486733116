import { GameConfig } from './game';
import * as PIXI from 'pixi.js';
import * as PixiFilters from 'pixi-filters';
import { AlbumButton } from './AlbumButton';
import { Sticker } from './sticker';
import { House } from './House';
import { TrashBin } from './TrashBin';
import Dyplom from './dyplom';


const ALBUM_COLOR = 0xFFFFFF;
const ALBUM_HEIGHT = 150;
const ALBUM_BUTTON_WIDTH = 120;
const ALBUM_BUTTON_HEIGHT = 100;
const BUTTONS_ON_LIST = 7;

const ALBUM_Y = 650;

export default class HomeGenerator extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;

    private house?: House;

    private trash?: TrashBin;

    public resources?: Partial<Record<string, PIXI.LoaderResource>>;

    constructor(width: number, height: number, backgroundColor: number, private config: GameConfig) {
        super({
            width,
            height,
            backgroundColor
        });

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;

        window.addEventListener('resize', this.gameResize)

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.initGame()
        })
    }

    public reset() {
        this.stickers.forEach(s => s.destroy())
        this.stickers = [];

        this.sendOnChange();
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {
            this.config.objects.forEach(o => {
                this.loader.add(o.key, o.src)
                this.loader.add(`icon_${o.key}`, o.iconSrc);
            });

            this.loader.add('home', '/images/generator3/home.png')
            this.loader.add('arrow', '/images/generator3/home-arrow.jpg')
            this.loader.add('shop', '/images/generator3/home-shop.png')
            this.loader.add('stopka', '/images/generator3/stopka.png');
            // this.loader.add('logo', '/images/logo/polin-simple.png');


            this.loader.add('duza_gora_1', '/images/generator/dyplom/g4533.png');
            this.loader.add('duza_gora_2', '/images/generator/dyplom/g4601.png');
            this.loader.add('mala_gora_1', '/images/generator/dyplom/g5508.png');

            this.loader.add('zwierzak_1', '/images/generator/dyplom/g4999.png');
            this.loader.add('zwierzak_2', '/images/generator/dyplom/g5035.png');
            this.loader.add('zwierzak_3', '/images/generator/dyplom/g5071.png');
            this.loader.add('zwierzak_4', '/images/generator/dyplom/g5107.png');
            this.loader.add('zwierzak_5', '/images/generator/dyplom/g5117.png');

            this.loader.add('korona_1', '/images/generator/dyplom/g4577.png');
            this.loader.add('korona_2', '/images/generator/dyplom/g4581.png');
            this.loader.add('korona_3', '/images/generator/dyplom/g4737.png');
            this.loader.add('korona_4', '/images/generator/dyplom/g4991.png');
            this.loader.add('korona_5', '/images/generator/dyplom/g4729.png');

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })
    }

    private initGame() {

        // if (this.resources?.logo) {
        //     const logo = new PIXI.Sprite(this.resources.logo.texture);
        //     logo.anchor.set(1, 0);
        //     logo.x = this.baseWidth - 75;
        //     logo.y = 0;
        //     logo.scale.set(175 / logo.width);
        //     this.stage.addChild(logo)
        // }

        this.initTrash();
        this.initAlbum();
        this.initHouse();
        this.initStickers();
        this.initTooltip();

        /* DEV */
        // this.getSceenBlob();

    }

    private initTooltip() {
        this.stage.addChild(this.tooltip);
        this.hideTooltip();
    }
    private initTrash() {
        this.trash = new TrashBin(this.resources);
        this.stage.addChild(this.trash);

        this.trash.x = this.baseWidth * .85;
        this.trash.height = ALBUM_HEIGHT;
        this.trash.y = ALBUM_Y;
    }

    private initHouse() {
        if (this.resources?.home) {
            this.house = new House({
                house: this.resources.home.texture,
                onChange: () => this.sendOnChange()
            })

            this.house.y = 10;

            this.stage.addChild(this.house);
        }
    }

    private albumContainer?: PIXI.Graphics;
    private albumList?: PIXI.Container;
    private arrowLeft?: PIXI.Sprite;
    private arrowRight?: PIXI.Sprite;

    private initAlbum() {

        const albumWidth = this.baseWidth * 0.80;
        const listWidth = BUTTONS_ON_LIST * ALBUM_BUTTON_WIDTH;
        const arrowWidth = (albumWidth - listWidth) / 2;

        this.albumContainer = new PIXI.Graphics();
        this.albumContainer.height = ALBUM_HEIGHT;
        this.albumContainer.width = albumWidth;
        this.albumContainer.y = ALBUM_Y;
        this.albumContainer.x = this.baseWidth * .02;

        //@ts-ignore
        this.albumContainer.filters = [new PixiFilters.OutlineFilter(2, 0x000000)]

        this.albumContainer.beginFill(ALBUM_COLOR);
        this.albumContainer.drawRect(0, 0, albumWidth, ALBUM_HEIGHT);
        this.albumContainer.endFill();

        if (this.resources) {
            this.arrowLeft = new PIXI.Sprite(this.resources.arrow?.texture)

            this.arrowLeft.anchor.set(0.5);
            this.arrowLeft.y = ALBUM_HEIGHT / 2;
            this.arrowLeft.x = arrowWidth / 2;
            this.arrowLeft.scale.set((ALBUM_BUTTON_HEIGHT * .75) / this.arrowLeft.height)
            this.arrowLeft.interactive = true;
            this.arrowLeft.buttonMode = true;
            this.arrowLeft.on('click', this.scrollLeft)

            this.arrowRight = new PIXI.Sprite(this.resources.arrow?.texture);

            this.arrowRight.anchor.set(.5);
            this.arrowRight.y = ALBUM_HEIGHT / 2;
            this.arrowRight.x = this.albumContainer.width - arrowWidth / 2;
            this.arrowRight.anchor.set(.5);
            this.arrowRight.rotation = Math.PI
            this.arrowRight.scale.set(75 / this.arrowRight.height);
            this.arrowRight.interactive = true;
            this.arrowRight.buttonMode = true;
            this.arrowRight.on('click', this.scrollRight)

            this.albumContainer.addChild(this.arrowRight);
            this.albumContainer.addChild(this.arrowLeft);



            this.albumList = new PIXI.Container();
            this.albumList.width = listWidth;
            this.albumList.height = ALBUM_HEIGHT;
            this.albumList.x = 0;
            this.albumList.y = 0;

            const listMask = new PIXI.Graphics();

            listMask.x = arrowWidth;
            listMask.y = 0;
            listMask.beginFill(0x000000);
            listMask.drawRect(0, 0, listWidth, ALBUM_HEIGHT)
            listMask.endFill();

            const listWindow = new PIXI.Container();
            listWindow.width = listWidth;
            listWindow.height = ALBUM_HEIGHT;
            listWindow.x = arrowWidth;
            listWindow.y = 0;

            this.albumList.mask = listMask;
            this.albumContainer.addChild(listMask);
            this.albumContainer.addChild(listWindow);
            listWindow.addChild(this.albumList);

            this.config.objects
                .sort((a, b) => {
                    return b.isAvaiable ? 1 : -1;
                })
                .forEach((o, index) => {

                    //@ts-ignore
                    const buttonTexture: Texture = this.resources[`icon_${o.key}`]?.texture

                    //@ts-ignore
                    const stickerTexture: Texture = this.resources[o.key]?.texture

                    if (buttonTexture) {
                        const button = new AlbumButton(buttonTexture, ALBUM_BUTTON_WIDTH / 2 + (ALBUM_BUTTON_WIDTH * index), ALBUM_HEIGHT / 2, o.key, o.isAvaiable);

                        if (o.isAvaiable) {
                            //@ts-ignore
                            button.on('pointerdown', (event: PIXI.interaction.InteractionEvent) => (
                                this.addSticker(stickerTexture, button.x, button.y, o.key, event)
                            ));
                        } else {
                            //@ts-ignore
                            button.on('pointermove', (e: PIXI.interaction.InteractionEvent) => {
                                if (this.isTooltipShowed) {
                                    this.moveTooltip(e.data.global.x, e.data.global.y);
                                }
                            })
                            //@ts-ignore
                            button.on('pointerover', (e: PIXI.interaction.InteractionEvent) => {
                                this.showTooltip(o.unavaialbeLabel, e.data.global.x, e.data.global.y)
                            })
                            button.on('pointerout', () => {
                                this.hideTooltip();
                            })
                        }

                        if (this.albumList) {
                            this.albumList.addChild(button);
                        }
                    }
                });
        }

        this.stage.addChild(this.albumContainer);
        this.updateArrows();
    }

    private isRightAvailable = () => {
        if (this.albumList) {
            return (this.config.objects.length - BUTTONS_ON_LIST) * ALBUM_BUTTON_WIDTH > -this.albumList.x;
        }

        return false;
    }

    private isLeftAvailable = () => {
        if (this.albumList) {
            return (this.albumList.x < 0);
        }

        return false;
    }

    private scrollRight = () => {
        if (this.albumList) {
            this.albumList.x -= ALBUM_BUTTON_WIDTH;
            this.updateArrows();
        }
    }

    private scrollLeft = () => {
        if (this.albumList) {
            this.albumList.x += ALBUM_BUTTON_WIDTH;
            this.updateArrows();
        }
    }

    private updateArrows() {
        if (this.arrowRight) {
            this.arrowRight.visible = this.isRightAvailable();
        }

        if (this.arrowLeft) {
            this.arrowLeft.visible = this.isLeftAvailable();
        }
    }

    private stickers: Sticker[] = [];
    
    //@ts-ignore
    private addSticker(t: PIXI.Texture, x: number, y: number, key: string, createEvent?: PIXI.interaction.InteractionEvent) {
        if (this.house) {
            const sprite = new Sticker(t, x, y, key, createEvent)
            sprite
                .on('pointerdown', this.onDragStart)
                .on('pointerup', this.onDragEnd)
                .on('pointerupoutside', this.onDragEnd)
                .on('pointermove', this.onDrag)

            this.house.addChild(sprite);

            this.stickers.push(sprite);
        }
    }

    private destroySticker(sticker: Sticker) {
        this.stickers = this.stickers.filter(s => s !== sticker);

        sticker.destroy();
    }

    //@ts-ignore
    private onDrag = (e: PIXI.interaction.InteractionEvent) => {
        const x = e.data.global.x;
        const y = e.data.global.y;
    }

    private draggedSticker?: PIXI.DisplayObject | null;

    //@ts-ignore
    private onDragStart = (e: PIXI.interaction.InteractionEvent) => {
        this.draggedSticker = e.currentTarget
    }

    //@ts-ignore
    private onDragEnd = (e: PIXI.interaction.InteractionEvent) => {
        this.draggedSticker = null;

        if (this.trash?.dropzone.contains(e.data.global.x, e.data.global.y)) {
            this.destroySticker(e.currentTarget as Sticker);
            this.trash.drawNormal();
        }

        this.sendOnChange();
    }

    private sendOnChange = () => {

        const stickers = this.stickers.map(o => {
            return {
                key: o.key,
                position: {
                    y: o.y,
                    x: o.x
                }
            }
        })

        if (this.house) {
            this.config.onChange({
                stickers                
            })
        } else {
            this.config.onChange({ stickers })
        }

    }

    private initStickers() {
        //@ts-ignore
        this.config.state.stickers.map(s => this.addSticker(this.resources[s.key]?.texture, s.position.x, s.position.y, s.key))
    }

    public gameResize = () => {
        const nW = this.config.container.offsetWidth;
        const nH = nW * this.baseRatio;

        this.renderer.resize(nW, nH);

        this.scaleRatio = (nW / this.baseWidth);

        this.stage.scale.set(this.scaleRatio)
    }

    public destroy() {
        window.removeEventListener('resize', this.gameResize);
    }

    public async getSceenBlob(): Promise<string> {
        if (this.house) {
            const mask = new PIXI.Graphics();
            mask.beginFill(0xFF3300);
            mask.drawRect(this.house.x, this.house.y, this.house.width, this.house.height);
            mask.endFill();


            const mask2 = new PIXI.Graphics();
            mask2.beginFill(0xfff8e6);
            mask2.drawRect(0, 0, this.house.width, this.house.height);
            mask2.endFill()

            // this.castle.addChildAt(mask2, 0)

            this.stage.mask = mask;
            // this.castle.hideUX();


            const castleBase64 = this.renderer.plugins.extract.base64(this.house, 'image/png', 1);

            const t = PIXI.Texture.from(castleBase64);

            // Robimy opóźnienie aby dyplom zdołą się wyrenderować;
            await new Promise(resolve => setTimeout(resolve, 0));

            const dyplom = new Dyplom(
                this.config.userName,
                this.config.emblem,
                new PIXI.Sprite(t),
                this,
                {
                    x: 0,
                    y: this.house.getYCorrection()
                });

            /* DEV */
            // this.stage.addChild(dyplom);
            // dyplom.x = 0;
            // dyplom.y = this.baseHeight / 2;

            const result = this.renderer.plugins.extract.base64(dyplom, 'image/png', 1);

            // definicje nie zgadzająsię z dokumentacją https://pixijs.download/dev/docs/PIXI.Container.html#mask
            //@ts-ignore
            this.stage.mask = null;

            this.house.removeChild(mask2);

            return result;
        } else {

            return '';
        }
    }

    private tooltip = new PIXI.Text('ssssss', { fontFamily: 'Arial', fontSize: 24, fill: 0xff1010, align: 'center' });
    private isTooltipShowed = false;

    private showTooltip(text: string, x: number, y: number) {
        // this.isTooltipShowed = true;
        // this.tooltip.text = text;

        // this.tooltip.x = x;
        // this.tooltip.y = y;
    }

    private moveTooltip(x: number, y: number) {
        this.tooltip.x = x;
        this.tooltip.y = y;
    }

    private hideTooltip() {
        this.isTooltipShowed = false;
        this.moveTooltip(-9999, -9999);
    }
}

