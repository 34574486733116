import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import HomePage from './routes/Home';

import { getHomeRoute, getMapRoute, getTaskRoute, getGeneratorRoute, getRulesRoute, getManualRoute, TASK_TO_URI_TABLE, getEndRoute } from './routes';
import MapPage from './routes/Map';
import TaskPage from './routes/Task';
import GeneratorPage from './routes/Generator';
import PageBackground from './layout/background';
import RulesPage from './routes/Rules';
import ManualPage from './routes/Manual';
import LoginPage from './routes/Login';
import LandingPage from './routes/LandingPage';
import EndPage from './routes/End';
import Cookies from './components/cookies';
import { useAcceptedCookies } from './modules/main';
import ScrollToTop from './utils/scroll-to-top';
import useUser from './modules/user/hooks/use-user';
import Music from './components/music';

/**
 * Czy strona jest już właczona
 */
function useEventStarted() {
    return Date.now() > 1590915600000;
}

/**
 * Czy spektakl jest dostepny
 */
export function useShowAvailable() {
    return Date.now() < 1591016400000;
}

export default function App() {

    const isEventStarted = useEventStarted();
    const isAccepedCookies = useAcceptedCookies()

    const { name } = useUser();

    const isLogged = Boolean(name)

    const showFullPage = isEventStarted;
    const showLandingPage = !isEventStarted;

    const showCookiePopup = !isAccepedCookies;

    return (
        <PageBackground>
            <BrowserRouter>
                <ScrollToTop />
                {showCookiePopup && <Cookies />}
                <Music src='/sounds/music.wav' />
                {showFullPage && <Switch>                    
                    <Route path={getHomeRoute()} exact>
                        <HomePage />
                    </Route>
                    <Route path={getRulesRoute()}>
                        <RulesPage />
                    </Route>
                    <Route path={getMapRoute()}>
                        <MapPage />
                    </Route>
                    {TASK_TO_URI_TABLE.map((data, index) => {
                        return (
                            <Route key={index} path={data[1]}>
                                <TaskPage id={data[0]} />
                            </Route>
                        )
                    })
                    }
                    <Route path={getTaskRoute()}>
                        <TaskPage />
                    </Route>
                    <Route path={getGeneratorRoute()}>
                        <GeneratorPage />
                    </Route>
                    <Route path={getManualRoute()}>
                        <ManualPage />
                    </Route>                    
                    <Route path={getEndRoute()}>
                        <EndPage />
                    </Route>
                    <Route><HomePage /></Route>
                </Switch>}
            </BrowserRouter>
        </PageBackground>
    )
}
