import React from "react"

type Props = {
    code: string;
}

export default function Puzzle({ code }: Props) {
    return (
        <div style={{ width: '100%', display: 'inline-block' }}
            dangerouslySetInnerHTML={
                { __html: code }
            }>
        </div>
    )
}
