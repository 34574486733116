import React, { useMemo, useCallback, useState, useReducer } from "react";
import './style.scss';
import PlayerAndDrop from "./player-and-drop";

export default function ShoppingList() {

    const DATA = useMemo(() => {
        return [
            {
                label: 'bateria AA',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/autobus.mp3',
                image: '/tasks/102/elektryczny-b.png'
            },
            {
                label: 'klucz żabka',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/czekolada.mp3',
                image: '/tasks/102/narzedzia-b.png'
            },
            {
                label: 'bateria zlewozmywakowa',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/gitara.mp3',
                image: '/tasks/102/kuchnia-b.png'
            },
            {
                label: 'deska sedesowa',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/keczup.mp3',
                image: '/tasks/102/lazienka-b.png'
            },
            {
                label: 'deska tarasowa',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/pizza.mp3',
                image: '/tasks/102/ogrod-b.png'
            },
            {
                label: 'wałek malarski',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/robot.mp3',
                image: '/tasks/102/farby-b.png'
            },
            {
                label: 'półwałek drewniany',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/spagetti.mp3',
                image: '/tasks/102/stolarka-b.png'
            },
            {
                label: 'żabki do zasłon',
                audio: 'https://www.polin.pl/sites/default/files/2020-05/sweter.mp3',
                image: '/tasks/102/dekoracje-b.png'
            } 
        ]
    }, [])

    const RANDOM_DATA = useMemo(() => {
        return [...DATA].sort(() => Math.random() - .5)
    }, [DATA]);

    const [activeWord, setActiveWord] = useState('')
    const [correctCount, addCorrect] = useReducer((state: number) => {
        return ++state;
    }, 0)

    const onDragStart = useCallback((e: React.DragEvent) => {
        e.dataTransfer.setData('text', e.currentTarget.id)
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.setData('text', e.currentTarget.id);

        setActiveWord(e.currentTarget.id)

    }, [])

    const onDragEnd = useCallback((e) => {
        setTimeout(() => {
            setActiveWord('')
        }, 1000)
    }, [setActiveWord])

    const onCorrect = useCallback((id: string) => {
        addCorrect()
    }, [])

    return (
        <>
            <div className='shopping-list'>
                <div className="pickzones">
                    {
                        RANDOM_DATA.map((e, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="pickzone">
                                        <span
                                         id={e.label}
                                         draggable={true}
                                         onDragStart={onDragStart}
                                         onDragEnd={onDragEnd}>{e.label}</span>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                <div className="shop-zones">
                    {
                        DATA.map((e, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <PlayerAndDrop
                                        audio={e.audio}
                                        answer={e.label}
                                        activeWord={activeWord}
                                        image={e.image}
                                        onCorrect={onCorrect}
                                    />
                                </React.Fragment>
                            )
                        })
                    }

                </div>

            </div>
            {(correctCount === DATA.length) && <p>Gratulacje!</p>}
        </>
    )
}
