import React, { useState, useCallback, useMemo } from "react";
import './style.scss';

export default function ShadowsGame() {

    const DATA = useMemo(() => [
        {
            shadow: '/tasks/301/byk1.png',
            animal: '/tasks/301/byk.png',
            label: 'byk'
        },
        {
            shadow: '/tasks/301/indyk1.png',
            animal: '/tasks/301/indyk.png',
            label: 'indyk'
        },
        {
            shadow: '/tasks/301/kogut1.png',
            animal: '/tasks/301/kogut.png',
            label: 'kogut'
        },
        {
            shadow: '/tasks/301/lew1.png',
            animal: '/tasks/301/lew.png',
            label: 'lew'
        },
        {
            shadow: '/tasks/301/ryba1.png',
            animal: '/tasks/301/ryba.png',
            label: 'ryba'
        },
        {
            shadow: '/tasks/301/slon1.png',
            animal: '/tasks/301/slon.png',
            label: 'slon'
        },
        {
            shadow: '/tasks/301/wiewiorka1.png',
            animal: '/tasks/301/wiewiorka.png',
            label: 'wiewiorka'
        }
    ], [])

    const onDragStart = useCallback((e: React.DragEvent) => {
        e.dataTransfer.setData('text', e.currentTarget.id)
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.setData('text', e.currentTarget.id);
    }, [])

    const onDragEnd = useCallback((e) => {
    }, [])

    const elements = useMemo(() => {
        return DATA.reduce<any[]>((prev, next) => {
            return [
                ...prev,
                { type: 'drop', data: next },
                { type: 'pick', data: next }
            ]
        }, []).sort(() => {return Math.random() - .5})
    }, [DATA])

    return (
        <div className='shadows-game'>
            <div className="dropzones">
                {elements.map((el, index) => {
                    switch (el.type) {
                        case 'drop':
                            return (
                                <Dropzone
                                    key={index}
                                    background={el.data.shadow}
                                    answer={el.data.label}
                                    onCorrect={() => {}}
                                    correctBackground={el.data.animal}
                                />)

                        case 'pick':
                            return (
                                <div className="pickupZone" key={index}>
                                    <img
                                        src={el.data.animal}
                                        alt=""
                                        id={el.data.label}
                                        draggable={true}
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                    />
                                </div>)

                        default:
                            return (<></>)
                    }
                })}
            </div>
        </div>
    )
}

type DropProps = {
    background: string,
    answer: string,
    correctBackground: string,
    onCorrect: (id: string) => void
}

function Dropzone({ answer, background, correctBackground, onCorrect }: DropProps) {

    const [isOver, setIsOver] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [isWrong, setIsWrong] = useState(false);

    const onDragOverDropZone = useCallback((e: React.DragEvent) => {
        if (!isCorrect) {
            e.preventDefault();
            setIsOver(true);
        }
    }, [isCorrect])

    const onDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();

        if (!isCorrect) {
            const data = e.dataTransfer.getData('text')

            setIsOver(false);

            if (data === answer) {
                setIsCorrect(true);
                onCorrect(answer)
            } else {
                setIsWrong(true);

                setTimeout(() => {
                    setIsWrong(false);
                }, 1000)
            }
        }

    }, [isCorrect, answer, onCorrect])

    const onDragLeave = useCallback((e) => {
        setIsOver(false);
    }, [])

    return (
        <div className={`dropzone ${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''} ${isOver ? 'over' : ''}`}
            style={{ backgroundImage: `url(${isCorrect ? correctBackground : background})` }}
            onDragOver={onDragOverDropZone}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
        </div>
    )
}
