import React, { useMemo, useCallback, useState } from "react";
import './style.scss';

export default function ImageLottery() {

    const IMAGES = useMemo(() => [
        'aparat_1200',
        'armata_1200',
        'banany_1200',
        'baran200',
        'buty_1200',
        'chlopiec1200',
        'chlopiec2200',
        'domek_1200',
        'dzban200',
        'dziewczynka200',
        'gepart200',
        'grabie_1200',
        'gryf_1200',
        'indyk_1200',
        'jablko200',
        'jednorozec_1200',
        'jelen_1200',
        'kapec_1200',
        'kapelusz_1200',
        'kielbaski200',
        'klodka_1200',
        'kolo_1200',
        'kompas_1200',
        'korona_1200',
        'koszyk_1200',
        'latarka_1200',
        'lew_1200',
        'lornetka_1200',
        'lyzwy_1200',
        'miecz_1200',
        'mis_1200',
        'okulart200',
        'pedzel_1200',
        'pilka2200',
        'piorko_1200',
        'plecak200',
        'podkowa_1200',
        'pszczolka_1200',
        'ptak200',
        'ratunkowe200',
        'rekawiczki200',
        'ryba_1200',
        'sanki_1200',
        'skarpeta_1200',
        'skrzynia_1200',
        'slon_1200',
        'srubokret_1200',
        'swinka_1200',
        'telefon_1200',
        'walizka_1200',
        'wiewiorka200',
        'winogrono_1200',
        'worek_braz200',
        'wrotki_1200',
        'zajac_1200',
        'zeszyt200',
    ].map(n => `/tasks/102/${n}.png`), []);

    const [selectedImages, setSelectedImages] = useState<(string | null)[]>([
        null, null, null, null, null, null
    ]);

    const onClickRandom = useCallback(() => {
        const result: string[] = [];

        while (result.length <= 5) {
            const n = IMAGES[Math.floor(Math.random() * IMAGES.length)];

            if (result.indexOf(n) === -1) {
                result.push(n);
            }
        }

        setSelectedImages(result);

    }, [IMAGES])

    return (
        <div className="image-lottery">
            <div className="images">
                {
                    selectedImages.map((i, index) => (
                        <div className='imgContainer' key={index}>
                            <img src={i || '/tasks/102/patern.png'} alt="" />
                        </div>
                    ))
                }
            </div>

            <div className='button-container'>
                <button className='button' onClick={onClickRandom}>Losuj</button>

            </div>
        </div>

    )
}
