import React, { useState, useEffect, useCallback } from "react"
import './style.scss';

type Props = {
    data: Img[]
}

type Img = {
    src: string,
    description: string
}

const SLIDER_INTERVAL = 5000;

export default function ImageSliderBzy(/*{data}: Props*/) {

    const data = [
        {
            src: '/tasks/203/utopia.org.png',
            description: '',
            source: {
                label: 'utopia.org',
                src: 'http://utopia.org'
            },
            isCC: false
        },
        {
            src: '/tasks/203/mudsongs.org.png',
            description: '',
            source: {
                label: 'mudsongs.org',
                src: 'http://mudsongs.org'
            },
            isCC: false
        },
        {
            src: '/tasks/203/wildflowermeadows.com.png',
            description: '',
            source: {
                label: 'wildflowermeadows.com',
                src: 'http://wildflowermeadows.com'
            },
            isCC: false
        },
        {
            src: '/tasks/203/frame3.png',
            description: '',
            source: {
                label: '',
                src: ''
            },
            isCC: false
        },
        //     src: '/tasks/204/A-maze-ing_Laughter_vancouver_cameron_norman_flickr.jpg',
        //     description: 'A-maze-ing Laughter, autor: Yue Minjun, Morton Park, Vancouver, Canada, 2009.',
        //     source: {
        //         label: 'cameron_norman | flickr',
        //         src: 'https://www.flickr.com/people/10316646@N07'
        //     },
        //     isCC: true
        // },
    ]

    const [imgIndex, setImgIndex] = useState(0)
    const [isAutoMode, setAutoMode] = useState(false);

    const nextImage = useCallback(() => {
        setImgIndex((imgIndex + 1) % data.length);
    }, [setImgIndex, imgIndex, data.length])

    const prevImage = useCallback(() => {
        setImgIndex((imgIndex + data.length - 1) % data.length);
    }, [setImgIndex, imgIndex, data.length])

    useEffect(() => {
        if (isAutoMode) {
            const id = setTimeout(() => {
                nextImage();
            }, SLIDER_INTERVAL)
            return () => { clearTimeout(id) }
        }

    }, [imgIndex, nextImage, isAutoMode])


    const toggle = useCallback(() => {
        setAutoMode(!isAutoMode);
    }, [setAutoMode, isAutoMode])

    const next = useCallback(() => {
        nextImage();
    }, [nextImage]);

    const prev = useCallback(() => {
        prevImage();
    }, [prevImage]);

    return (
        <div className="img-slider-bzy">

            <figure>
                <div className="image">
                    <div className='controls'>
                        <div className="left">
                            {!isAutoMode && <button onClick={prev} className="prev"></button>}
                        </div>
                        <div className="center">
                            <button onClick={toggle} className={isAutoMode ? 'pause' : 'play'}></button>
                        </div>
                        <div className="right">
                            {!isAutoMode && <button onClick={next} className="next"></button>}
                        </div>
                    </div>
                    <img className="slide" src={data[imgIndex].src} alt="" />
                </div>
                <figcaption>
                    {data[imgIndex].description}<br />
                    <div className="legal">

                        {data[imgIndex].source.label && <>
                            Źródło: <a href={data[imgIndex].source.src}>{data[imgIndex].source.label}</a>
                        </>}
                        
                        {data[imgIndex].isCC && <>
                            <br />
                            <a rel="license" href="http://creativecommons.org/licenses/by/2.0/">
                                <img
                                    alt="Licencja Creative Commons"
                                    style={{ borderWidth: 0 }}
                                    src="https://i.creativecommons.org/l/by/2.0/88x31.png"
                                />
                            </a>Ten utwór jest dostępny na{'\u00A0'}<a rel="license" href="http://creativecommons.org/licenses/by/2.0/">licencji Creative Commons Uznanie autorstwa 2.0 Ogólny</a>.
                    </>}
                    </div>
                </figcaption>
            </figure>
        </div>
    )

}
