import React from "react";
import './style.scss';

export default function PageFooter() {
    return (
        <footer className="page-footer">
            <hr />
            <h3>Organizatorzy:</h3>
            <nav className='logo-list'>
                <a href="https://www.leroymerlin.pl/" className="leroymerlin" target="_blank">
                    <img src="/images/logo/logo.png" alt="Leroy Merlin" />
                </a>
                <a href="#" className="silnirazem">
                    <img src="/images/logo/sr-logo.png" alt="Silni Razem" />
                </a>
            </nav>
        </footer>
    )
}
