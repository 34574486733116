import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import useTask from '../../modules/game/hooks/use-task';
import CenterLolumnLayout from '../../layout/screen';
import { useSetPatternBackground } from '../../layout/background';
import PageFooter from '../../components/page-footer';
import './style.scss';
import { getMapRoute, getEndRoute } from '../../routes';
import Task106 from './CustomTasks/Task106';
import Task202 from './CustomTasks/Task202';
import Task203 from './CustomTasks/Task203';
import Task405 from './CustomTasks/Task405';
import Task407 from './CustomTasks/Task407';
import Task104 from './CustomTasks/Task104';

import { Link } from 'react-router-dom';
import DefaultTask from './default-task';

type Props = {
    id?: string
}

const TaskPage: React.FunctionComponent<Props> = ({ id }) => {
    const { id: uri_id } = useParams();
    const { task, isLoading } = useTask(parseInt(id || uri_id));
    const history = useHistory();


    const onCompleteTask = useCallback(() => {
        playTaskEnd();
        if (
            (task?.id === 106) ||
            (task?.id === 206) ||
            (task?.id === 306) )
        {
            playEnd();
        }

        if (task) {
            history.push(getMapRoute(), {
                task
            })
        }
    }, [task, history])

    const onCompleteTaskClicked = useCallback((e: React.MouseEvent) => {        
        e.preventDefault();
        onCompleteTask()
    }, [onCompleteTask])

    const playTaskEnd = () => {
        let snd = new Audio("/sounds/task-end.wav");
        snd.play();
    }

    const playEnd = () => {
        let snd = new Audio("/sounds/wiwat.wav");
        snd.play();
    }

    const setBackground = useSetPatternBackground();
    const patterns = [
        'pattern-generator',
        'pattern-sklep',
        'pattern-karnawal',
        'pattern-spolecznosc'        
    ]

    useEffect(() => {
        // @ts-ignore
        let iid = parseInt(id);
        if ((iid > 100) && (iid < 200)) {
            setBackground(`/images/backgrounds/pattern-sklep.png`);
        } else if ((iid > 200) && (iid < 300)) {
            setBackground(`/images/backgrounds/pattern-spolecznosc.png`);
        } else if ((iid > 300) && (iid < 400)) {
            setBackground(`/images/backgrounds/pattern-karnawal.png`);
        } else {
            setBackground(`/images/backgrounds/${patterns[Math.floor(Math.random() * patterns.length)]}.png`)
        }
    }, [id, patterns, setBackground])

    if (!task && !isLoading) {
        return (<p>Coś poszło nie tak</p>)
    }

    const playClick2 = () => {
        let snd = new Audio("/sounds/click2.wav");
        snd.play();
    }

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <div className="to-map">
                <Link to={getMapRoute()} onClick={playClick2} className="button-image button-to-map">
                    {/* <img src="/images/buttons/do-sklepu.png" alt="Do sklepu" /> */}
                </Link>
            </div>
            {(() => {
                switch (task?.id) {                    
                    case 104:
                        return (
                            <Task104
                                task={task as Task}
                            />
                        )
                    case 106:
                        return (
                            <Task106
                                task={task as Task}
                            />
                        )                        

                    // case 202:
                    //     return (
                    //         <Task202
                    //             task={task as Task}
                    //         />
                    //     )

                    // case 203:
                    //     return (
                    //         <Task203
                    //             task={task as Task}
                    //         />
                    //     )

                    case 405:
                        return (
                            <Task405
                                task={task as Task}
                            />
                        )



                    case 407:
                        return (
                            <Task407
                                task={task as Task}
                            />
                        )

                    default:
                        return (
                            <DefaultTask
                                task={task as Task}
                            />
                        )

                }
            })()}

            {task?.id !== 307 && <div className="button-complete">
                <a href={getMapRoute()} className='button-image button-complete-task' onClick={onCompleteTaskClicked}></a>                
            </div>}

        </CenterLolumnLayout>
    )
}

export default TaskPage;
