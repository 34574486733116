import makeStore from "../../utils/create-store";
import reducer from './reducer';

// const GAME_MODULE_LS_KEY = 'tasks-module'

export type GameModuleState = {
    isLoaded: boolean,
    isLoading: boolean,
    tracks: Track[],
    tasks: Task[],
    generator: {
        stickers: {id: string, src: string, iconSrc: string}[]
    }
}

const initGameModuleState: GameModuleState = {
    isLoaded: false,
    isLoading: false,
    tracks: [],
    tasks: [],
    generator: {
        stickers: []
    }
}

export enum GAME_MODULE_ACTION {
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH = 'FETCH'
}

export type GameModuleAction = {
    type: GAME_MODULE_ACTION,
    payload?: any
}

const [
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
] = makeStore(reducer, initGameModuleState)


export {
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
}
