import Korony from "./korony";

const BASE_WIDTH = 1366;
const BASE_HEIGHT = 700;

export type GameConfig = {
    container: HTMLElement
}

export default function createGame(config: GameConfig) {

    const app = new Korony(BASE_WIDTH, BASE_HEIGHT, 0xffffff, config)

    config.container.appendChild(app.view);
    app.gameResize();

    // ====================== //

    return {
        getSceenBlob: () => app.getSceenBlob(),
        uninstall: () => {
            app.destroy();
        }
    };
}
