import React from "react";
import TaskElements from "../../taks-elements";
import './style.scss'

type Props = {
    task: Task
}

const Task405: React.FunctionComponent<Props> = ({ task }) => {
    return (
        <div className="task-screen column task-405">

            <h1>{task.title}</h1>

            <p>
                Król Maciuś podczas wizyty w Muzeum POLIN wąchał przyprawy schowane w besamimce, bo taki był zwyczaj na zakończenie szabatu. Postanowił, że każdy rycerz i rycerka muszą mieć własną besamimkę. Będą mogli ją powąchać, by przypomnieć sobie coś miłego. Zróbcie własne małe pudełko z wonnościami, korzystając z szablonu.
            </p>
            <p>
                Jak zrobić besamimkę?
            </p>
            <ol style={{width: '100%'}}>
                <li>Wytnijcie pudełko. Użyjcie sztywnego kartonu. Tnijcie po czarnych liniach.</li>
                <li>Ozdóbcie pudełko.</li>
                <li>Zegnijcie papier wzdłuż przerywanych linii i uformujcie pudełko.</li>
                <li>Do środka włóżcie pachnące zioła (na przykład: suszoną lawendę, goździki, ziele angielskie, liść laurowy, cynamon w lasce)</li>
                <li>Zamknijcie pudełko.</li>
                <li>Powąchajcie ☺</li>
            </ol>

            <TaskElements
                elements={task?.elements || []}
            />
        </div>)
}

export default Task405;
