import React, { useEffect } from 'react';
import styled from 'styled-components';
import CenterLolumnLayout from '../../layout/screen';
import './style.scss';
import { useSetPatternBackground } from '../../layout/background';
import { Link, useLocation } from 'react-router-dom';
import PageFooter from '../../components/page-footer';
import { getMapRoute } from '../../routes';


const IntroScreen = styled.main``

export default function IntroPage() {  

    const setBackground = useSetPatternBackground();
    useEffect(() => {
        setBackground('/images/backgrounds/pattern-generator.png');
    })

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <IntroScreen className='intro-screen column'>                
                <img src="/images/rules/grafika-zasady.png" alt="zasady" />
                <h1>Zasady</h1>

                <p>Po kliknięciu w przycisk <strong>„Dalej”</strong> przejdziecie do wirtualnego Feryjnego Sklepu Leroy Merlin. Podczas zwiedzania sklepu wykonajcie zadania z dwóch ścieżek, które przez niego prowadzą.</p>
                
                <ol>
                    <li>W miejscach oznaczonych <span className="green">zielonymi tabliczkami</span> czekają na Was zadania do wykonania.</li>
                    <li>W niektórych zadaniach znajdziecie karty pracy. Możecie je pobrać na komputer klikając w <span className="green">przycisk „Pobierz”</span>, a następnie wydrukować.</li>
                    <li>Ukończenie zadania należy potwierdzić klikając w <span className="green">przycisk „Zadanie wykonane”</span>.</li>
                    <li>Aby wykonać następne zadanie, najpierw należy ukończyć to, które jest dla Was dostępne.</li>
                    <li>Za ukończenie zadania otrzymacie <span className="green">wirtualne nagrody</span>.</li>
                    <li>Gdy ukończycie dwie ścieżki, czeka na Was ostatnia - <span className="green">finałowa część gry</span>.</li>
                    <li>Nagrody możecie wykorzystać, by stworzyć swój wymarzony dom. By do niego przejść, kliknijcie w <span className="green">tabliczkę z napisem „Kasy”</span>.</li>
                    <li>W kreatorze możecie wykorzystać tę samą nagrodę wiele razy.</li>
                    <li>W dowolnej chwili możecie <span className="green">pobrać projekt swojego domu, klikając „Pobierz projekt”</span>.</li>
                    <li>W trakcie gry, gdy zechcecie, możecie wyłączyć grającą melodię za pomocą przełączników umieszczonych w prawym górnym rogu.</li>
                    <li>W każdej chwili możecie przerwać zabawę i wrócić do niej później.</li>
                    <li>Na czas tej przygody <span className="green">stwórzcie swój własny <strong>Dziennik z Zielonych Ferii</strong></span>, w którym będziecie mogli zapisywać lub rysować swoje pomysły i rozwiązania zadań. Róbcie zdjęcia upamiętniające efekty wykonywanych zadań i pracy nad nimi.</li>
                    <li>Aby wrócić później do spisu zasad, kliknijcie <span className="green">na mapie tabliczkę z napisem „Punkt informacyjny”</span>.</li>
                    <li>Po ukończeniu wszystkich zadań (widocznych i tajnych) możecie pochwalić się efektami waszej pracy w specjalnie przygotowanej galerii. Wyślijcie swój dziennik i zdjęcia na adres e-mail: <span className="green"><a href="mailto:zieloneferie@leroymerlin.pl">zieloneferie@leroymerlin.pl</a></span>, koniecznie z e-maila zakończonego @leroymerlin.pl.</li>                    
                </ol>
                
                <p>
                    <strong>UWAGA RODZICE:</strong><br />
                    Zasady uczestnictwa w zabawie znajdziecie <a href="/regulamin-gry-zielone-ferie.pdf" target="_blank" rel="doc">tutaj</a>.<br />
                    Pamiętajcie, że wysyłając pracę Waszego dziecka na adres <a href="mailto:zieloneferie@leroymerlin.pl" target="_blank">zieloneferie@leroymerlin.pl</a> wyrażacie zgodę na wykorzystanie tych prac przez Dział Komunikacji Korporacyjnej na potrzeby komunikacji wewnętrznej w materiałach komunikacyjnych związanych z akcją <span className="green">"Zielone Ferie"</span>.
                </p>

                <Link to={getMapRoute()} className="button-image button-dalej"></Link>                
            </IntroScreen>
        </CenterLolumnLayout>
    )
}
