import React, { useEffect } from "react";
import CenterLolumnLayout from "../../layout/screen";
import './style.scss'
import PageFooter from "../../components/page-footer";
import { Link } from "react-router-dom";
import { getMapRoute, getGeneratorRoute } from "../../routes";
import { useSetPatternBackground } from "../../layout/background";

export default function EndPage() {
    const setBackground = useSetPatternBackground();
    
    const patterns = [
        'pattern-generator'    
    ]

    useEffect(() => {
        setBackground(`/images/backgrounds/${patterns[Math.floor(Math.random() * patterns.length)]}.png`)
    })

    return (
        <CenterLolumnLayout footer={PageFooter()}>

            <div className="end-page">
                <h1>Zakończenie turnieju</h1>
                <p>
                    Gratulacje!<br/>                    
                    Zakończyliście swoją przygodę, wykonaliście wszystkie zadania!
                    Teraz możecie pochwalić się swoim Dziennikiem z Zielonych Ferii.                    
                    W tym celu zbierzcie wszystkie Wasze zdjęcia, dzieła oraz zapiski i prześlijcie je na adres: <strong><a href="mailto:zieloneferie@leroymerlin.pl" target="_blank">zieloneferie@leroymerlin.pl</a></strong>, koniecznie z e-maila zakończonego @leroymerlin.pl.
                </p>
                <p>
                    Dziękujemy za wspólną przygodę i do zobaczenia!
                </p>
                <p>
                    Zespół Feryjnego Sklepu Leroy Merlin<br/>
                    Jeśli chcecie wrócić do któregoś z zadań lub skończyć Wasz projekt domu, możecie kontynuować zabawę wracając do mapy.
                </p>
                <nav>
                    <div className="buttons">
                        <Link className="button-image button-dosklepu" to={getMapRoute()}></Link>
                        <Link className="button-image button-dokreatora" to={getGeneratorRoute()}></Link>
                    </div>
                </nav>
            </div>

        </CenterLolumnLayout >
    )
}
