import React from "react"
import createStore from "../../utils/create-store"
import SolidBodyBackgound, { SolidColorBackgroundState, useSetSolidColorBackground } from "./solid-color-background";
import FullImageBodyBackgound, { FullImageBackgroundState, useSetFullImageColorBackground } from "./full-image-background";
import PatternBackground, {PatternBackgroundState, useSetPatternBackground} from './pattern-background';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'BgTag': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
        }
    }
}
export enum BACKGROUND_TYPE {
    SOLID_COLOR,
    FULL_IMAGE,

    PATTERN
}

type BackgroundState = SolidColorBackgroundState | FullImageBackgroundState | PatternBackgroundState;

const reducer = (state: BackgroundState, action: { type: 'set' | 'reset', newState?: BackgroundState }) => {

    switch (action.type) {
        case 'set':
            return action.newState || state;

        case 'reset':
            return initBackgroudState;
    }
}

const initBackgroudState: BackgroundState = {
    type: BACKGROUND_TYPE.SOLID_COLOR,
    color: '#019686'
}

const [
    BackgrondProdiver,
    useBackgroundDispacher,
    useBackgroundState
] = createStore(reducer, initBackgroudState);

const PageBackground: React.FunctionComponent = ({ children }) => {
    return (
        <BackgrondProdiver>
            <BackgroundSwitcher>
                {children}
            </BackgroundSwitcher>
        </BackgrondProdiver>)
}

const BackgroundSwitcher: React.FunctionComponent = ({ children }) => {

    const { type } = useBackgroundState();

    const BgTag = (() => {
        switch (type) {
            case BACKGROUND_TYPE.SOLID_COLOR:
                return SolidBodyBackgound;

            case BACKGROUND_TYPE.FULL_IMAGE:
                return FullImageBodyBackgound;

            case BACKGROUND_TYPE.PATTERN:
                return PatternBackground;
        }
    })()

    return (
        <BgTag>
            {children}
        </BgTag>
    )
}


export {
    useBackgroundDispacher,
    useBackgroundState
}

export {
    useSetSolidColorBackground,
    useSetFullImageColorBackground,
    useSetPatternBackground
}

export default PageBackground;
