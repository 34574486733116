import HomeGenerator from "./HomeGenerator";
import * as PIXI from 'pixi.js';

export default class Dyplom extends PIXI.Container {

    private frontPlane = new PIXI.Container()
    private backPlane = new PIXI.Container()

    private baseWidth = 3508;
    private baseHeight = 2480;

    constructor(
        private userName: string,
        private emblem: string,
        castle: PIXI.Sprite,
        private app: HomeGenerator,
        private coorection: { x: number, y: number }
    ) {
        super();

        const g = new PIXI.Graphics();
        g.beginFill(0xffffff);
        g.drawRect(0, 0, this.baseWidth, this.baseHeight)
        g.endFill();

        this.addChild(g);

        this.addChild(this.backPlane);

        const g2 = new PIXI.Graphics()
        this.addChild(g2);

        this.addChild(castle)
        this.addChild(this.frontPlane);

        const CASTLE_SCALE = 2;
        castle.scale.set(CASTLE_SCALE);
        castle.anchor.set(.5, 0);
        castle.x = this.baseWidth / 2
        // castle.y = (this.baseHeight * .35);
        castle.y = (this.baseHeight * .25) - this.coorection.y * CASTLE_SCALE;

        this.initTitle();
        this.initFooter();

        /* DEV */
        // this.scale.set(this.app.baseWidth / this.baseWidth)
    }

    private initFooter() {
        if (this.app.resources) {
            const g1 = new PIXI.Sprite(this.app.resources.stopka?.texture);

            g1.anchor.set(.5, 1);
            g1.x = this.baseWidth / 2;
            g1.y = this.baseHeight;
            g1.scale.set(1)
            this.backPlane.addChild(g1);
        }
        
    }

    private initTitle() {
        // const g = new PIXI.Graphics();

        // g.lineStyle(15, 0x682F12, 1);
        // g.moveTo(this.baseWidth * .2, 200);
        // g.lineTo(this.baseWidth * .8, 200);
        // g.closePath();

        // this.addChild(g);

        //-----------------------

        const text = this.getNameText();

        text.anchor.set(.5, 0);

        text.x = this.baseWidth / 2;
        text.y = 180;

        this.addChild(text);

        //-----------------------

        // const sqTop = text.y + text.height + 45;
        // const sqSize = 20;

        // const sqLineLenght = this.baseWidth * .6;

        // const sqCount = (sqLineLenght / (sqSize * 2)) | 0;
        // const sqGap = (sqLineLenght - (sqCount * sqSize)) / (sqCount - 1);


        // g.lineStyle(0, 0, 0);
        // g.beginFill(0x000000);

        // for (let i = 0; i < sqCount; i++) {
        //     g.drawRect(this.baseWidth * .2 + (i * (sqSize + sqGap)), sqTop - sqSize / 2, sqSize, sqSize)
        // }

        // g.endFill();
    }

    private getNameText(fontSize: number = 100): PIXI.Text {

        let text;

        do {
            text = new PIXI.Text(`MÓJ WYMARZONY DOM`, {
                fontFamily: 'LeroyMerlinSans',
                fontSize,
                fontWeight: 'bold',
                align: 'center',
                fill: 0x6CAF22,
                wordWrap: true,
                wordWrapWidth: this.baseWidth * .6
            })
            fontSize = fontSize - 5;
        } while (text.height > 250)

        return text;
    }
}
