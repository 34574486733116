import React, { useCallback, useState, useEffect } from "react"
import FullscreenImageOverlay from "../full-screen-overlay";
import './style.scss'
export enum DOCUMENT_FORMAT {
    PDF = 'pdf',
    IMAGE = 'image'
}


type Props = {
    thumbnails: {
        src: string,
        full: FullscreenImage[]
    }[],
    document: string,
    altLabel: string,
    description?: string,
    format: DOCUMENT_FORMAT
}

type FullscreenImage = {
    src: string,
    ratio: number
}

export default function DocumentPrezenter({ thumbnails, document, format, altLabel }: Props) {

    const [displayOverlay, setDisplayOverlay] = useState<null | FullscreenImage[]>(null);

    const toFullScreen = useCallback((data: FullscreenImage[]) => {
        setDisplayOverlay(data);
    }, [setDisplayOverlay]);


    const closeFullscreen = useCallback(() => {
        setDisplayOverlay(null);
    }, [])

    const closeFullscreenOnEscape = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape' || e.keyCode === 27) {
            closeFullscreen();
        }
    }, [closeFullscreen])

    useEffect(() => {
        window.document.addEventListener('keydown', closeFullscreenOnEscape)

        return () => { window.document.removeEventListener('keydown', closeFullscreenOnEscape) }
    }, [closeFullscreenOnEscape])

    return (
        <div className="doc-prezenter">
            <div className="thumbnails">
                {
                    thumbnails.map((t, index) => {
                        return (<div key={index}><img src={t.src} className={t.full ? 'clickable' : ''} alt={altLabel} onClick={() => toFullScreen(t.full)} /></div>)
                    })
                }
            </div>

            {document && <>
                <a href={document} download className="button-image button-pobierz"></a>                            
            </>}
            {
                displayOverlay && displayOverlay.length && <FullscreenImageOverlay
                    images={displayOverlay}
                    alt={altLabel}
                    onClick={() => { setDisplayOverlay(null) }}
                />
            }
        </div>
    )
}
