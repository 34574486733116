import { useRef, useCallback } from "react";
import React from "react";
import useGame from "../../modules/game/hooks/use-game";
import { getMapRoute, getEndRoute } from "../../routes";
import { Link } from "react-router-dom";
import './style.scss'
import GameComponent from "./game-component";

export default function Generator3() {
    const { game, isLoaded } = useGame();
    const gameRef = useRef<any>();

    const onDownloadClick = useCallback((e: React.MouseEvent) => {
            e.preventDefault();
            gameRef.current.getImage().then((image: string) => {
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(b64toBlob(image.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')), 'domek.png');
                } else {
                    var a = document.createElement('a');
                    a.href = image;
                    a.style.display = 'none';
                    a.setAttribute('download', 'domek.png');
                    document.body.appendChild(a);
                    a.click();
                }
            })
    }, [gameRef])

    const onResetClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        gameRef.current.reset();
    }, [])

    return (
        <div className='generator'>
            {isLoaded && <GameComponent ref={gameRef} game={game} />}

            <div className="under-game">
                <div className="controls">
                    <a className='button-image button-project' download onClick={onDownloadClick} href="/"></a>
                    <Link className='button-image button-shop' to={getMapRoute()} ></Link>
                    <a className='button-image button-reset' onClick={onResetClick}></a>
                    {/* <Link className='button' to={getEndRoute()} >Zakończ turniej</Link>                 */}
                </div>  
                <div className="rules">
                    <h3>Instrukcja</h3>
                    <p className='subtitle'>Wykorzystajcie zdobyte nagrody, by urządzić swój wymarzony dom.</p>
                    <ul>
                        <li>Poniżej ilustracji przedstawiającej dom znajdziecie listę przedmiotów. By ją przeglądać, użyjcie <span className="green">zielonych strzałek</span> po bokach.</li>
                        <li>Nagrody, które zdobyliście są kolorowe. Nagrody, które dopiero zdobędziecie są wyciemnione.</li>                        
                        <li>Zdobyte nagrody możecie użyć w domu wielokrotni</li>
                        <li>Jeśli zmienicie zdanie, możecie usunąć przedmiot z domu, przesuwając go na tabliczkę z napisem „Zwróć do sklepu”.</li>
                        <li>W dowolnym momencie możecie pobrać Wasz projekt z kreatora, używając przycisku z napisem <span className="green">„Pobierz projekt”</span>. Później możecie go wydrukować na pamiątkę.</li>
                    </ul>                   
                </div>
            </div>

        </div>
    )
}


const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
