import React from "react";
import './style.scss'
import TaskElements from "../../taks-elements";

type Props = {
    task: Task
}

const Task104: React.FunctionComponent<Props> = ({ task }) => {

    return (
        <div className="task-screen column task-104">

            <h1>{task.title}</h1>

            <p>Ułóżcie wszystkie elementy w jak najkrótszym czasie i zanotujcie swój wynik w Dzienniku. Zastanówcie się, jakie elementy powinno zawierać logo Feryjnego Sklepu Leroy Merlin, by przekazywało ducha współpracy i gotowości do pomocy. Zanotujcie swoje pomysły i narysujcie własną wizję w Dzienniku.</p>            

            <div style={{ width: '100%', display: 'inline-block' }}>
                <div className="puzzle-wrapper" style={{ width: '65%', display: 'inline-block' }}>
                    <TaskElements
                        elements={task?.elements || []}
                    />
                </div>
                <div className="instruction-wrapper"  style={{ width: '35%', display: 'inline-block', verticalAlign: "top" }}>
                    <aside>
                        <header>INSTRUKCJA</header>
                        <dl>
                            <div><dt><img src="/tasks/104/info-1.png" alt="ikona 1" /></dt><dd>klikając w  tę ikonkę możecie wybrać ilość elementów układanki</dd></div>
                            <div><dt><img src="/tasks/104/info-2.png" alt="ikona 2" /></dt><dd>kliknięcie w tę ikonkę powoduje obrócenie elementów i utrudnia</dd></div>
                            <div><dt><img src="/tasks/104/info-3.png" alt="ikona 3" /></dt><dd>po kliknięciu w tę ikonę, możecie wybrać kolor tła</dd></div>
                            <div><dt><img src="/tasks/104/info-4.png" alt="ikona 4" /></dt><dd>po kliknięciu w ten guzik rozpoczniecie układanie</dd></div>
                        </dl>
                    </aside>
                </div>
            </div>
            




        </div>)
}

export default Task104;
