import React, { useReducer, useEffect } from "react";
import './style.scss';

export default function Fishes() {

    return (
        <div className="fishes">
            <div className="inner">
                <div className="container">
                    <FishyButton className="b1" isCorrect={true} label="ryba 1"></FishyButton>
                    <FishyButton className="b2" isCorrect={false} label="ryba 2"></FishyButton>
                    <FishyButton className="b3" isCorrect={false} label="ryba 3"></FishyButton>
                    <FishyButton className="b4" isCorrect={true} label="ryba 4"></FishyButton>
                    <FishyButton className="b5" isCorrect={false} label="ryba 5"></FishyButton>
                </div>
            </div>
        </div>
    )
}


type BProps = {
    className: string,
    label: string,
    isCorrect: boolean
    onChange?: (value: boolean, id: string) => void
}

const FishyButton: React.FunctionComponent<BProps> = ({ className, label, onChange, isCorrect }) => {

    const [selected, toggle] = useReducer((state) => {
        return !state;
    }, false);

    useEffect(() => {
        if (onChange) onChange(selected, label)
    }, [selected, onChange, label])

    return (
        <span
            className={`${className} ${selected? 'selected' : ''} ${isCorrect ? 'correct' : ''}`}
            tabIndex={0}
            role="checkbox"
            aria-checked={selected}
            aria-label={label} onClick={(e) => {
            toggle()
        }}></span>
    )
}
