import React, { useMemo, useState, useCallback, useReducer, useEffect } from "react";
import useInterval from './use-interval';
import MemoryGrid from "./memory-grid";

enum GAME_PHASE {
    READY = 'READY', 
    WAITING = 'WAITING',
    COMPARING = 'COMPARING',
    ENDED = 'ENDED'
}

export default function MemoryGame() {

    const gridWidth = 8;
    const gridHeight = 3;

    const showGameInitError = Boolean((gridHeight * gridWidth) % 2);
    const showGame = !showGameInitError;

    const images = useMemo(() => [
        '/tasks/102/budowlany',
        '/tasks/102/dekoracje',
        '/tasks/102/elektryczny',
        '/tasks/102/farby',
        '/tasks/102/hydrauliczny',
        '/tasks/102/kuchnia',
        '/tasks/102/lazienka',
        '/tasks/102/narzedzia',
        '/tasks/102/ogrod',
        '/tasks/102/oswietlenie',
        '/tasks/102/plytki',
        '/tasks/102/stolarka',
    ], []);

    const back = '/tasks/102/back.png'

    const [tiles, setTiles] = useState(() => {
        return images.reduce<TileModel[]>((prev, next, index) => {
            let image1 = next + '-a.png';
            let image2 = next + '-b.png';
            return [
                ...prev,
                { value: index, image: image1, isHidden: true, isRemoved: false, isMismatched: false },
                { value: index, image: image2, isHidden: true, isRemoved: false, isMismatched: false }
            ].sort(() => Math.random() - .5)
        }, [])
    })

    const [phase, setPhase] = useState<GAME_PHASE>(GAME_PHASE.READY);
    const [time, setTime] = useState({h:"0",m:"00",s:"00"});
    const [count, setCount] = useState<number>(0);
    // Dynamic delay
    const [delay, setDelay] = useState<number>(1000);
    // ON/OFF
    const [isPlaying, setPlaying] = useState<boolean>(false);

    useInterval(
        () => {
            // Your custom logic here
            setCount(count + 1);
            setTime(secondsToTime(count));
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? delay : null,
    )

    const [activeTiles, setActiveTile] = useReducer((state: number[], value: number | null) => {

        if (value === null) {
            return [];
        }

        if (value === state[0]) {
            return state;
        }

        if (state.length < 2) {
            return [
                ...state,
                value
            ]
        }

        return state;
    }, []);

    const onTileSelected = useCallback((tile: number) => {
        if (phase === GAME_PHASE.READY) {
            setPlaying(true);
            setPhase(GAME_PHASE.WAITING);
        }

        if (phase !== GAME_PHASE.WAITING && phase !== GAME_PHASE.READY) return;

        // Nie reagujemy na kliknięcia w odsłonięte płyki.
        if (!tiles[tile].isHidden) return;

        // Nie reagujemy na kliknięcia w usuniete płytki.
        if (tiles[tile].isRemoved) return;

        setActiveTile(tile);

    }, [phase, setPhase, setActiveTile, tiles])

    useEffect(() => {               
        if (phase === GAME_PHASE.ENDED) {
            setPlaying(false);
        }
    }, [phase])

    useEffect(() => {
        const newTiles = [
            ...tiles
        ]

        let dirty = false;

        activeTiles.forEach(t => {

            if (newTiles[t].isHidden) {
                newTiles[t].isHidden = false;
                dirty = true;
            }
        })

        if (dirty) setTiles(newTiles);
    }, [activeTiles, tiles, setTiles])

    useEffect(() => {
        if (activeTiles.length !== 2) return;

        // Jeżeli odkryliśmy 2 kafelki porównujemy ich wartości
        setPhase(GAME_PHASE.COMPARING);
        let id: number;

        const newTiles = [
            ...tiles
        ]

        // Jeżeli wartości się zgadzają
        if (tiles[activeTiles[0]].value === tiles[activeTiles[1]].value) {
            tiles[activeTiles[0]].isHidden = false;
            tiles[activeTiles[0]].isRemoved = true;
            tiles[activeTiles[1]].isHidden = false;
            tiles[activeTiles[1]].isRemoved = true;


            setActiveTile(null)
            setTiles(newTiles);
            setPhase(GAME_PHASE.WAITING);

        } else {
            id = window.setTimeout(() => {
                tiles[activeTiles[0]].isMismatched = true;
                tiles[activeTiles[1]].isMismatched = true;
                setActiveTile(null)
                setTiles(newTiles);

                setTimeout(() => {
                    tiles[activeTiles[0]].isHidden = true;
                    tiles[activeTiles[1]].isHidden = true;
                    tiles[activeTiles[0]].isMismatched = false;
                    tiles[activeTiles[1]].isMismatched = false;

                    setPhase(GAME_PHASE.WAITING);
                }, 1000)
            }, 500);
        }

        return () => clearTimeout(id);

    }, [activeTiles, setPhase, tiles, setTiles])

    useEffect(() => {
        if (tiles.every(t => t.isHidden === false)) {
            setPhase(GAME_PHASE.ENDED);
        }
    }, [tiles])

    const secondsToTime = (secs: any) => {
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours.toString(),
          "m": (minutes < 10)? "0" + minutes.toString() : minutes.toString(),
          "s": (seconds < 10)? "0" + seconds.toString() : seconds.toString()
        };
        
        return obj;
    };
      
    return (
        <>
            {
                showGameInitError &&
                <p>Błędne dane wejściowe gry</p>
            }
            {
                showGame &&
                <div style={{width:'95%', maxWidth: '900px'}}>
                    <div className="timer-clock">
                        <p>{time.m}:{time.s}</p>
                    </div>
                    <MemoryGrid
                        gridWidth={gridWidth}
                        gridHeight={gridHeight}
                        models={tiles}
                        back={back}
                        onTileSelected={onTileSelected}
                    ></MemoryGrid>
                </div>
            }
        </>
    )
}

export type TileModel = {
    value: number,
    image: string,
    isHidden: boolean,
    isRemoved: boolean,
    isMismatched: boolean
}
