import React, { useEffect } from 'react';

import CenterLolumnLayout from '../../layout/screen';
import './style.scss';
import { useSetPatternBackground } from '../../layout/background';
import { Link } from 'react-router-dom';
import PageFooter from '../../components/page-footer';
import { getRulesRoute } from '../../routes';

export default function HomePage() {

    const setBackground = useSetPatternBackground();
    
    const patterns = [
        'pattern-generator',
        'pattern-sklep',
        'pattern-spolecznosc'        
    ]
    const playMusic = () => {
        // let snd = new Audio("/sounds/music.wav");
        // snd.play();        
        // snd.addEventListener('ended', function() {
        //     this.currentTime = 0;
        //     this.play();
        // }, false);
    }

    useEffect(() => {
        setBackground(`/images/backgrounds/${patterns[Math.floor(Math.random() * patterns.length)]}.png`)
    })

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className='column home-screen'>
                <a href="//www.leroymerlin.pl/" className="logo">
                    <img src="/images/logo/logo.png" alt="Leroy Merlin - Sklepy budowlano-dekoracyjne Leroy Merlin" />
                </a>
                <h1>Witajcie!</h1>
                <p>
                    Odkryjcie niezwykły sklep, który otworzyliśmy dla Was specjalnie na czas zimowych ferii.<br />
                    Samodzielnie czy też wspólnie z rodzicami poznajcie jego sekrety i wykonajcie wszystkie czekające na Was zadania.
                </p>

                <Link to={getRulesRoute()} onClick={playMusic} className='button-image button-dalej'></Link>         
            </main>
        </CenterLolumnLayout>
    )
}
