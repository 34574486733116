import CenterLolumnLayout from "../../layout/screen";
import React from "react";

const ManualPage: React.FunctionComponent = () => {
    return (
        <CenterLolumnLayout>
            <h1>Instrukcja</h1>
            <ol>
                <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maxime nam sed nesciunt quaerat, ea vero, illum nostrum quisquam eius, officia libero. Quae consequatur dolorem quis obcaecati officiis eveniet veritatis voluptatum.</li>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, quidem. Adipisci ducimus, totam amet quis delectus voluptate ratione at repellat, in minima eius facilis quaerat error sed dicta mollitia sit!</li>
                <li>
                    <ol>
                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur, voluptatibus ut necessitatibus tenetur accusantium deserunt ducimus deleniti distinctio quasi consequatur magnam repellendus esse nobis doloremque! Perspiciatis accusamus hic veniam sit.</li>
                        <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sed quos, eligendi cumque recusandae delectus distinctio saepe odit tempora aut velit voluptate iusto est soluta nemo harum necessitatibus iure ullam ab?</li>
                    </ol>
                </li>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, quidem. Adipisci ducimus, totam amet quis delectus voluptate ratione at repellat, in minima eius facilis quaerat error sed dicta mollitia sit!</li>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, quidem. Adipisci ducimus, totam amet quis delectus voluptate ratione at repellat, in minima eius facilis quaerat error sed dicta mollitia sit!</li>
            </ol>
        </CenterLolumnLayout>
    )
}

export default ManualPage;
