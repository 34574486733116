import React, { useEffect, useRef, useCallback, useState } from "react";
import './style.scss';
import { getGeneratorRoute, getTaskRoute, getRulesRoute, getEndRoute } from "../../routes";
import { Link, useHistory } from "react-router-dom";

import * as Bowser from "bowser";
import useUserCompletedTasks from "../../modules/user/hooks/use-user-completed-tasks";
import useTask from "../../modules/game/hooks/use-task";
const browser = Bowser.getParser(window.navigator.userAgent);

const SWITCH_RATIO = 1.2;

export default function MapV2() {

    const [lClass, setLayoutClass] = useState('');
    const history = useHistory();

    const mainContainer = useRef<HTMLDivElement>(null);
    const mapContainer = useRef<HTMLDivElement>(null);

    const resize = useCallback(() => {
        const wW = window.innerWidth;
        const wH = window.innerHeight;

        const wRatio = wW / wH;

        if (mapContainer.current && mainContainer.current) {

            const isMobile = browser.is('mobile');
            const isColumn = wRatio >= SWITCH_RATIO;

            if (isColumn && !isMobile) {
                setLayoutClass(`map-column desktop`);
                mapContainer.current.style.zoom = '' + window.devicePixelRatio;
                mapContainer.current.style.height = `auto`
                return;
            }

            if (isColumn && isMobile) {
                setLayoutClass(`map-column mobile`);
                return;
            }

            if (!isColumn && !isMobile) {
                setLayoutClass(`map-row desktop`);
                mapContainer.current.style.zoom = '' + window.devicePixelRatio;
                mapContainer.current.style.height = `${100 * window.devicePixelRatio}%`
                return;
            }

            if (!isColumn && isMobile) {
                setLayoutClass(`map-row mobile`);
            }
        }

    }, [])

    useEffect(() => {
        setTimeout(resize)
        // setTimeout(resize, 10000)
        // resize();

        window.addEventListener('resize', resize);

        return () => window.removeEventListener('resize', resize);
    })

    const completedTasks = useUserCompletedTasks();

    const getTaskState = useCallback((id: number) => {
        if ((id > 300 && id < 400) && (getExtraRouteState() === 'hidden-extra-route')) {
            return 'hidden future';
        }

        if (completedTasks.find(t => t === id)) {
            return 'completed'
        }

        if (completedTasks.find(t => t === id - 1)) {
            return 'active'
        }

        if (id === 101 || id === 201 || id === 301 || id === 401) {
            return 'active'
        }

        return 'future';
    }, [completedTasks])

    const getExtraRouteState = useCallback(() => {
        return (completedTasks.length < 12)? 
            'hidden-extra-route' : '';
    }, [completedTasks])

    const toRulesClicked = useCallback((e: React.MouseEvent) => {
        playClick2()
        e.preventDefault()
        history.push(getRulesRoute(), {
            fromMap: true
        })
    }, [history])

    const playClick2 = () => {
        let snd = new Audio("/sounds/click2.wav");
        snd.play();
    }

    const playEnd = () => {
        let snd = new Audio("/sounds/wiwat.wav");
        snd.play();
    }

    return (
        <>
            <main className={`map-o ${lClass} ${getExtraRouteState()}`} ref={mapContainer}>
                <div className="map-c" ref={mainContainer}>
                    <div className="map-c2">
                        <div className="map-c3">                            
                            <Link to={getGeneratorRoute()} onClick={playClick2} className="do-generator">Kreator</Link>
                            <Link to={getRulesRoute()} onClick={toRulesClicked} className="zasady">Zasady</Link>                            

                            {
                                [
                                    101, 102, 103, 104, 105, 106,
                                    201, 202, 203, 204, 205, 206,
                                    301, 302, 303, 304, 305, 306,
                                    // 401, 402, 403, 404, 405, 406,
                                ].map(id => (
                                    <TaskLink key={id} id={id} state={getTaskState(id)} />
                                ))
                            }

                            <div className="to-end">
                                <Link to={getEndRoute()} onClick={playEnd} className="button-image button-to-end"></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}


type TLProps = {
    id: number,
    state: string
}


function TaskLink({ id, state }: TLProps) {
    const { task } = useTask(id);
    const playClick = () => {
        let snd = new Audio("/sounds/click.wav");
        snd.play();
    }
    return (
    <Link to={getTaskRoute(task?.id)} tabIndex={state === 'future' ? -1 : 0} onClick={playClick} className={`task t-${task?.id} ${state}`}>{task?.title} {state === 'completed' ? 'Zadanie wykonane' : 'Zadanie aktywne'}</Link>
    )
}
