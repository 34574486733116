import React, { useRef, useCallback, useEffect } from "react";
import './style.scss';

type Props = {
    src: string
}

export default function Music({ src }: Props) {

    const player = useRef<HTMLAudioElement>(null)

    const onPlayClicked = useCallback(() => {
        player.current?.play();
    }, [player])


    const onPauseClicked = useCallback(() => {
        player.current?.pause();
    }, [player])


    const onStopClicked = useCallback(() => {
        if (player.current) {
            player.current.pause();
            player.current.currentTime = 0;
        }
    }, [player])

    const onPlay = useCallback(() => { }, []);

    const onPause = useCallback(() => { }, []);

    const onEnded = useCallback(() => {
        onPlayClicked(); 
    }, [onPlayClicked]);

    const whaitToInteraction = () => {
        if (player.current?.paused) {
            setTimeout(
                function() {
                    console.log('try play music');
                    player.current?.play()                    
                    whaitToInteraction();
                },
                2000
            ); 
        }
    }

    useEffect(() => {
        setTimeout(
            function() {
                whaitToInteraction();
            },
            2000
        );        
        if (player.current) {
            const playerEl = player.current;
            playerEl.addEventListener('play', onPlay)
            playerEl.addEventListener('pause', onPause)
            playerEl.addEventListener('ended', onEnded)

            return () => {
                if (playerEl) {
                    playerEl.removeEventListener('play', onPlay)
                    playerEl.removeEventListener('pause', onPause)
                    playerEl.removeEventListener('ended', onEnded)
                }
            }
        }
    }, [onEnded, onPause, onPlay])

    return (
        <div className="music-player">
            <span className="player-button play" role="button" tabIndex={0} onClick={onPlayClicked}>Play</span>
            <span className="player-button pause" role="button" tabIndex={0} onClick={onPauseClicked}>Pause</span>
            <span className="player-button stop" role="button" tabIndex={0} onClick={onStopClicked}>Stop</span>
            <audio src={src} ref={player} />
        </div>
    )
}