import React, { useEffect, useRef, useCallback, useState } from "react";
import './style.scss';

import Bowser from 'bowser';

const isLegacy = Bowser.getParser(window.navigator.userAgent).is('Internet Explorer') || Bowser.getParser(window.navigator.userAgent).is('Microsoft Edge')


type Props = {
    images: {
        src: string,
        ratio: number
    }[],
    alt: string,
    onClick: () => void
}

const FullscreenImageOverlay: React.FunctionComponent<Props> = ({ images, alt, onClick }) => {

    const img = useRef<HTMLImageElement>(null)
    const [fitClass, setFitClass] = useState('');

    const [src, setSrc] = useState('');

    useEffect(() => {
        window.document.body.style.overflow = 'hidden';

        return () => {
            window.document.body.style.overflow = 'auto';
        }
    })

    const resize = useCallback(() => {
        if (img.current) {
            const wRatio = window.innerWidth / window.innerHeight;

            const img = images.reduce((prev, next) => {
                if (prev.src === '') return next;

                if (Math.abs(wRatio - prev.ratio) >= Math.abs(wRatio - next.ratio)) {
                    return next;
                }

                return prev;
            }, {src: '', ratio: 1})

            setSrc(img.src)

            if (isLegacy) {
                if (wRatio >= img.ratio) {
                    setFitClass('fit-height')
                } else {
                    setFitClass('fit-width')
                }
            }
        }
    }, [setFitClass, images])

    useEffect(() => {
        window.addEventListener('resize', resize)
        resize()

        return () => window.removeEventListener('resize', resize);

    }, [resize])

    return (
        <div className='overlay' onClick={onClick}>
            <img className={fitClass} src={src} alt={alt} ref={img} />
        </div>
    )
}

export default FullscreenImageOverlay;
