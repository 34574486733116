import makeStore from "../../utils/create-store";
import { useCallback } from "react";

const MAIN_MODULE_LS_KEY = 'main'

export type MainModuleState = {
    cookiesAccepted: boolean
}

enum ACTION {
    ACCEPT_COOKIE
}

export const initUserModuleState: MainModuleState = {
    cookiesAccepted: false
}

const [
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
] = makeStore((state, action: { type: ACTION }) => {
    switch (action.type) {
        case ACTION.ACCEPT_COOKIE:
            return {
                ...state,
                cookiesAccepted: true
            }
    }

    return state
}, initUserModuleState, MAIN_MODULE_LS_KEY)


export {
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
}



export function useAcceptedCookies() {
    const { cookiesAccepted } = useMainModuleState();

    return cookiesAccepted;
}

export function useAcceptCookies() {
    const dispatch = useMainModuleDispatch()

    return useCallback(() => {
        dispatch(
            {
                type: ACTION.ACCEPT_COOKIE
            }
        );
    }, [dispatch])
}
