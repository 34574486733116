import { useState, useCallback } from "react";
import React from "react";

type Props2 = {
    audio: string,
    answer: string,
    activeWord: string,
    onCorrect: (id: string) => void
}

export default function PlayerAndDrop({ audio, answer, activeWord, onCorrect }: Props2) {

    const [isOver, setIsOver] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [isWrong, setIsWrong] = useState(false);

    const onDragOverDropZone = useCallback((e: React.DragEvent) => {
        if (!isCorrect) {
            e.preventDefault();
            setIsOver(true);
        }
    }, [isCorrect])

    const onDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();

        if (!isCorrect) {
            const data = e.dataTransfer.getData('text')

            setIsOver(false);

            if (data === answer) {
                setIsCorrect(true);
                onCorrect(answer)
            } else {
                setIsWrong(true);

                setTimeout(() => {
                    setIsWrong(false);
                }, 500)
            }
        }

    }, [isCorrect, answer, onCorrect])

    const onDragLeave = useCallback((e) => {
        setIsOver(false);
    }, [])

    const showPrewiew = isOver && !isCorrect
    const label = isCorrect ? answer : (isOver ? activeWord : 'przeciągnij tutaj');

    return (
        <div className="player-with-drop">            
            <div className={`dropzone  ${isWrong ? 'wrong' : ''} ${isCorrect ? 'correct' : ''}`}
                onDragOver={onDragOverDropZone}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <span className={`answer ${showPrewiew ? 'preview' : ''} ${isCorrect ? 'correct' : ''} `}>{label}</span>
            </div>
            <div className="player">
                <img src={audio} alt="todo" /> 
            </div>
        </div>
    )
}
