import React, { useEffect } from "react";
import Generator3 from "../../components/generator3";
import CenterLolumnLayout from "../../layout/screen";
import { useSetPatternBackground } from "../../layout/background";

export default function GeneratorPage() {

    const setBackground = useSetPatternBackground();

    useEffect(() => {
        setBackground('/images/backgrounds/pattern-generator.png');
    }, [setBackground])

    return (
        <CenterLolumnLayout>
            <h1>KREATOR DOMU</h1>
            <p className='subtitle center'>Wykorzystujcie zdobyte nagrody by stworzyć swój wymarzony dom.</p>
            <Generator3 />
        </CenterLolumnLayout>
    )
}
